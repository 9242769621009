import { Flex } from "antd";
import { useTranslation } from "react-i18next";

const Loading = () => {
    const { t } = useTranslation();

    return (
        <Flex vertical justify='center' align='center' style={{ width: '100%', flex: '0 0 100%' }}>
            <div className="progress">
                <div className="progress-value"></div>
            </div>
            <span style={{ fontFamily: 'Roboto-Thin', fontSize: '0.75rem'}}> { t('messages.loading')} </span>
        </Flex>
    )
}

export default Loading;