import {
    ApolloClient,
    HttpLink,
    InMemoryCache,
    from
} from '@apollo/client';
import { 
	setContext
} from '@apollo/client/link/context';

/**
 * @description set the auth token to the header of the request
 */
const authLink = setContext((_, { headers }: any) => {
	const token = '';

    return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : ''
		}
	};
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
        authLink,
        new HttpLink({ uri: 'https://api.nest-hour.com/graphql' }),
    ])
});