import React from 'react';
import {
    RouterProvider
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import reportWebVitals from './reportWebVitals';
import router from './router/router';
import 'animate.css';
import { ApolloProvider } from '@apollo/client';
import { client } from './apollo/client';
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from 'react-i18next';
import en_translation from './translation/en.json';
import ca_translation from './translation/ca.json';
import es_translation from './translation/es.json';
import fr_translation from './translation/fr.json';
import de_translation from './translation/de.json';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: { translation: en_translation },
            ca: { translation: ca_translation },
            es: { translation: es_translation },
            fr: { translation: fr_translation },
            de: { translation: de_translation },
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
});

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <I18nextProvider i18n={i18n}>
                <RouterProvider router={router} fallbackElement={ <span> Loading... </span> } />
            </I18nextProvider>
        </ApolloProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
